<template>
	<canvas id="cas" style="background-color: #e1f0ff;"></canvas>
</template>

<script>
	let stop = null //动画重复渲染
	let canvas = null // canvas元素
	let ctx = null // canvas的绘制上下文
	let warea = {
		x: null,
		y: null,
		max: 20000
	}

	function init() { // 初始化canvas状态
		canvas = document.getElementById('cas');
		canvas.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		canvas.height = window.innerHeight - 80 || document.documentElement.clientHeight - 80 || document.body.clientHeight - 80;
		ctx = canvas.getContext('2d');
		if (canvas.getContext) {
			ctx = canvas.getContext('2d');
		} else {
			alert('您的浏览器不支持canvas绘画环境');
		}
	}

	function resize() {
		canvas.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		canvas.height = window.innerHeight - 80 || document.documentElement.clientHeight - 80 || document.body.clientHeight - 80 ;
	}

	let dots = []

	function resetDots() {
		// x，y为粒子坐标，xa, ya为粒子xy轴加速度，max为连线的最大距离
		for (var i = 0; i < 500; i++) {
			var x = Math.random() * canvas.width;
			var y = Math.random() * canvas.height;
			var xa = Math.random() * 2 - 1;
			var ya = Math.random() * 2 - 1;
			dots.push({
				x,
				y,
				xa,
				ya,
				max: 8000
			});
		}
	}

	function animate() {
		ctx.clearRect(0, 0, canvas.width, canvas.height);
		// 将鼠标坐标添加进去，产生一个用于比对距离的点数组
		var ndots = [warea].concat(dots);
		dots.forEach(function(dot) {
			// 粒子位移
			dot.x += dot.xa;
			dot.y += dot.ya;
			// 遇到边界将加速度反向
			dot.xa *= dot.x > canvas.width || dot.x < 0 ? -1 : 1;
			dot.ya *= dot.y > canvas.height || dot.y < 0 ? -1 : 1;
			// 绘制点
			ctx.fillRect(dot.x - 0.5, dot.y - 0.5, 1, 1);
			// 循环比对粒子间的距离
			for (var i = 0; i < ndots.length; i++) {
				var d2 = ndots[i];
				if (dot === d2 || d2.x === null || d2.y === null) continue;
				var xc = dot.x - d2.x;
				var yc = dot.y - d2.y;
				// 两个粒子之间的距离
				var dis = xc * xc + yc * yc;
				// 距离比
				var ratio;
				// 如果两个粒子之间的距离小于粒子对象的max值，则在两个粒子间画线
				if (dis < d2.max) {
					// 如果是鼠标，则让粒子向鼠标的位置移动
					if (d2 === warea && dis > d2.max / 2) {
						dot.x -= xc * 0.03;
						dot.y -= yc * 0.03;
					}
					// 计算距离比
					ratio = (d2.max - dis) / d2.max;
					// 画线
					ctx.beginPath();
					ctx.lineWidth = ratio / 2;
					//线条颜色
					ctx.strokeStyle = '#339eef';
					ctx.moveTo(dot.x, dot.y);
					ctx.lineTo(d2.x, d2.y);
					ctx.stroke();
				}
			}
			// 将已经计算过的粒子从数组中删除
			ndots.splice(ndots.indexOf(dot), 1);
		});

		RAF(animate);
	}

	function RAF(callback) {
		return (
			stop = window.requestAnimationFrame(callback) ||
			window.webkitRequestAnimationFrame(callback) ||
			window.mozRequestAnimationFrame(callback) ||
			window.oRequestAnimationFrame(callback) ||
			window.msRequestAnimationFrame(callback)
		)
	}


	export default {
        mounted() {
            init();
				resetDots();
				// 延迟100秒开始执行动画，如果立即执行有时位置计算会出错
                resize()
				setTimeout(() => {
					animate();
				}, 100);
				// 鼠标活动时，获取鼠标坐标
				window.onmousemove = (e) => {
					e = e || window.event;
					warea.x = e.clientX;
					warea.y = e.clientY;
				};
				window.onmouseout = () => {
					warea.x = null;
					warea.y = null;
				};
        },
        destroyed(){
            dots.length = 0 //清除点
            window.cancelAnimationFrame(stop)
        }
    }
</script>

<!-- 写在vue内部，渲染性能明显下降，动画顿挫感明显 -->
<!-- <script setup>
	import { reactive, onMounted, onUnmounted } from 'vue';

	let canvas = reactive(null);
	let ctx = reactive(null);
	// 鼠标活动时，获取鼠标坐标
	const warea = reactive({
		x: null,
		y: null,
		max: 20000
	});
	onMounted(() => {
		canvas = document.getElementById('cas');
		ctx = canvas.getContext('2d');
		window.onresize = resize();
		RAF(() => {});
		resetDots();
		// 延迟100秒开始执行动画，如果立即执行有时位置计算会出错
		setTimeout(function() {
			animate();
		}, 100);

		// 鼠标活动时，获取鼠标坐标
		window.onmousemove = function(e) {
			e = e || window.event;
			warea.x = e.clientX;
			warea.y = e.clientY;
		};
		window.onmouseout = function(e) {
			warea.x = null;
			warea.y = null;
		};
	});

	//定义画布大小的函数
	const resize = () => {
		canvas.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		canvas.height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
	};

	//定时设置 浏览器下次重绘之前继续更新下一帧动画
	const RAF = callback => {
		return (
			window.requestAnimationFrame(callback) ||
			window.webkitRequestAnimationFrame(callback) ||
			window.mozRequestAnimationFrame(callback) ||
			window.oRequestAnimationFrame(callback) ||
			window.msRequestAnimationFrame(callback) 
		);
	};


	// 添加粒子
	let dots = reactive([]);
	const resetDots = () => {
		// x，y为粒子坐标，xa, ya为粒子xy轴加速度，max为连线的最大距离
		for (var i = 0; i < 300; i++) {
			var x = Math.random() * canvas.width;
			var y = Math.random() * canvas.height;
			var xa = Math.random() * 2 - 1;
			var ya = Math.random() * 2 - 1;

			dots.push({
				x,
				y,
				xa,
				ya,
				max: 12000
			});
		}
	};

	const animate = () => {
		ctx.clearRect(0, 0, canvas.width, canvas.height);
		// 将鼠标坐标添加进去，产生一个用于比对距离的点数组
		var ndots = [warea].concat(dots);
		dots.forEach(function(dot) {
			// 粒子位移
			dot.x += dot.xa;
			dot.y += dot.ya;
			// 遇到边界将加速度反向
			dot.xa *= dot.x > canvas.width || dot.x < 0 ? -1 : 1;
			dot.ya *= dot.y > canvas.height || dot.y < 0 ? -1 : 1;
			// 绘制点
			ctx.fillRect(dot.x - 0.5, dot.y - 0.5, 1, 1);
			// 循环比对粒子间的距离
			for (var i = 0; i < ndots.length; i++) {
				var d2 = ndots[i];
				if (dot === d2 || d2.x === null || d2.y === null) continue;
				var xc = dot.x - d2.x;
				var yc = dot.y - d2.y;
				// 两个粒子之间的距离
				var dis = xc * xc + yc * yc;
				// 距离比
				var ratio;
				// 如果两个粒子之间的距离小于粒子对象的max值，则在两个粒子间画线
				if (dis < d2.max) {
					// 如果是鼠标，则让粒子向鼠标的位置移动
					if (d2 === warea && dis > d2.max / 2) {
						dot.x -= xc * 0.03;
						dot.y -= yc * 0.03;
					}
					// 计算距离比
					ratio = (d2.max - dis) / d2.max;
					// 画线
					ctx.beginPath();
					ctx.lineWidth = ratio / 2;
					//线条颜色
					ctx.strokeStyle = '#c5000a';
					ctx.moveTo(dot.x, dot.y);
					ctx.lineTo(d2.x, d2.y);
					ctx.stroke();
				}
			}
			// 将已经计算过的粒子从数组中删除
			ndots.splice(ndots.indexOf(dot), 1);
		});

		RAF(animate);
	};
</script> -->

<style lang="scss"></style>
