<template>
  <div class="header textleft">
    <div class="conten">
      <img class="logo ponit" :src="logo" @click="HomeRoute" alt="" />
    </div>
  </div>
</template>
<script>
import Routerpush from "@/mixins/Routerpush";
export default {
  mixins: [Routerpush],
  name: "LinkDistanceMallWebPublicheader",

  data() {
    return {
      logo: require("@/assets/img/logo.png"),
    };
  },

  mounted() {},

  methods: {},
};
</script>
<style lang="scss" scoped>
.header {
  width: 100%;
  background: #fff;
  .conten {
    width: 1200px;
    margin: auto;
  }
}
.logo {
  width: 190px;
  height: 78px;
}
</style>
