<template>
  <div>
    <PublicHeader />
    <pointLine class="bgs" />
    <router-view />
  </div>
</template>
<script>
import PublicHeader from "@/components/PublicHeader";
import pointLine from "@/components/pointLine";
export default {
  data() {
    return {};
  },
  components: {
    PublicHeader,
    pointLine,
  },
};
</script>
<style lang="scss" scoped>
.bgs {
  position: absolute;
  top: 78px;
  z-index: 0;
  left: 0px;
}
</style>
